import React, { useState } from 'react';

export default props => {
  const { pageContext } = props;
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [joinEmail, setJoinEmail] = useState('');

  const handleJoinFormSubmit = e => {
    e.preventDefault();

    fetch('/.netlify/functions/submission-created', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'form-name': 'contact',
        name: 'Unknown',
        email,
        message: `
            Subject: Join Coetic X
            Message: ${joinEmail}
        `,
      }),
    })
      .then(() => {
        setJoinEmail('');
      })
      .catch(() => {
        alert('Something went wrong!');
      });
  };

  const handleContactFormSubmit = e => {
    e.preventDefault();

    fetch('/.netlify/functions/submission-created', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'form-name': 'contact',
        name: name + ' ' + lastName,
        email,
        message: `
            Subject: ${subject}
            Message: ${message}
        `,
      }),
    })
      .then(() => {
        setName('');
        setLastName('');
        setEmail('');
        setSubject('');
        setMessage('');
      })
      .catch(() => {
        alert('Something went wrong!');
      });
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', maxHeight: '90%' }}>
      {pageContext?.index === 1 && (
        <>
          <h1 className="title">About Us</h1>
          <h3 className="sub-title">
            <b>Coetic X</b> is a startup that was founded on May of 2021.
          </h3>
          <h3 className="sub-title">
            We have already acquired 4 clients around the globe, <br />
            located in <b>California, New York, UK and Sweden.</b>
          </h3>
          <h3 className="sub-title">
            We have <b>a team of handpicked developers</b> that are highly efficient,
            <br />
            have a progressive mentality and an immense passion for programming.
          </h3>
          <h3 className="sub-title">
            Our plan is to <b>scale up, grow our network, connect with teams</b> and assist them with high quality code.
          </h3>
        </>
      )}
      {pageContext?.index === 2 && (
        <>
          <br />
          <h1 className="title">Objectives</h1>
          <h3 className="sub-title">Our primary objective is to support teams around the globe.</h3>

          <br />
          <div>
            <h1 className="title">Professional</h1>

            <ul className="sub-title-list">
              <li>Efficient Developers</li>
              <li>Progressive Mentality</li>
              <li>Learning Culture</li>
              <li>Mature and United Team</li>
              <li>Guarantee Top Quality</li>
              <li>Long Term Maintenance Service</li>
            </ul>
          </div>
          <br />
          <div>
            <h1 className="title">Speed</h1>
            <ul className="sub-title-list">
              <li>High Quality Trained Developers</li>
              <li>Family Mentality (case of urgency - team helps)</li>
              <li>Code Patterns</li>
            </ul>
          </div>
          <br />
          <div>
            <h1 className="title">Pricing</h1>
            <ul className="sub-title-list">
              <li>Fairly Low Pricing</li>
              <li>No need for Office Rent & Supplies</li>
              <li>Benefits / Taxes - Coetic X</li>
            </ul>
          </div>
          <br />
          <div>
            <h1 className="title">Safety</h1>
            <ul className="sub-title-list">
              <li>Outsource the Work - Not the Project</li>
              <li>Overview & Support</li>
              <li>Communication Ease</li>
              <li>NDA Contracts</li>
              <li>1 Month Warning</li>
              <li>Refund Policy - Bi-Weekly</li>
            </ul>
          </div>
        </>
      )}
      {pageContext?.index === 3 && (
        <>
          <form onSubmit={handleJoinFormSubmit}>
            <h1 className="title">Join Us</h1>

            <input className="join-us-input" type="email" name="email" placeholder="E-MAIL" value={joinEmail} onChange={e => setJoinEmail(e.target.value)} />
            <button type="submit">Submit</button>
          </form>
        </>
      )}
      {pageContext?.index === 4 && (
        <>
          <h1 className="title">Contact Us</h1>
          <form className="contact-us-form" onSubmit={handleContactFormSubmit}>
            <div>
              <input type="text" name="firstName" placeholder="FIRST NAME" value={name} onChange={e => setName(e.target.value)} />
              <input type="text" name="lastName" placeholder="LAST NAME" value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
            <input type="email" name="email" placeholder="E-MAIL" value={email} onChange={e => setEmail(e.target.value)} />
            <input type="text" name="subject" placeholder="SUBJECT" value={subject} onChange={e => setSubject(e.target.value)} />
            <textarea type="text" name="message" placeholder="MESSAGE" value={message} onChange={e => setMessage(e.target.value)} />
            <button>Submit</button>
          </form>
        </>
      )}
      {pageContext?.index === 5 && (
        <div className={'info'}>
          <p>Coetic X LLC</p>
          <p>
            <b>Phone:</b> +383 45 42 33 41
          </p>
          <p>
            <b>Email:</b> arber@coetic-x.com
          </p>
          <p>
            <b>Adress:</b> Metush Krasniqi n.30 / Selim Berisha n.16
          </p>
          <p>This site is owned and operated by Arber Shabani</p>
        </div>
      )}
    </div>
  );
};
